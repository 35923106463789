import {SiteCredentialsData} from '../../data'
import { Container } from '../../components/layout/Container/Container'
import { HeadFC, Link } from 'gatsby'
import * as globalStyles from '../../styles/global.module.scss'
import * as styles from './styles.module.scss'
import React from 'react'
import classNames from "classnames";
import NewLogo from "../../images/icons/new-logo.svg";



const HeaderLogo = () => (
    <Link to={'/'} className="inline-block leading-[0px]">
        <img src={NewLogo} className="inline-block min-h-[16px] w-[60px] tablet:min-h-[23px] tablet:w-[90px]" alt="Logo" />
    </Link>
)

const HeaderMini = () => {

    return (
        <header>
            <div className="relative flex items-center justify-between py-[26px] px-[16px] desktop:px-[50px] ">
                <div className={'flex items-center desktop:mr-[77px] '}>
                    <HeaderLogo />
                </div>
            </div>
        </header>
    )
}


const ErrorPage = () => {


    return (
        <main>

            <section className={styles.section}>
                <HeaderMini/>
                <Container className="pt-[50px] tablet:pt-[220px] desktop:pt-[160px]">
                    <div className="mx-auto flex max-w-[600px] flex-col items-center text-center ">
                        <h1 className={"mb-[16px] text-cts-white"}>Whoops!</h1>
                        <img src={require('./images/error.svg').default} alt="error" className="mb-[16px]" />
                        <p className="mb-[50px] text-cts-text-1 text-cts-white">
                            Please try again or email us{' '}
                            <a className={" text-[#92BBF9]" } href={`mailto:${SiteCredentialsData.email}`}>
                                {SiteCredentialsData.email}
                            </a>
                        </p>

                        <Link to={'/'} className={classNames(globalStyles.btnWhite,globalStyles.btn)}>
                            Go To HomePage
                        </Link>
                    </div>
                </Container>
            </section>
        </main>
    )
}

const Head: HeadFC = () => (
    <>
        <title>Something went wrong</title>
        <meta name="description" content="Please try again or email us" />
    </>
)




export { ErrorPage, Head }
