import classNames from 'classnames'
import React from 'react'

interface ContainerProps {
    children?: React.ReactNode
    className?: string
}

export const Container: React.FC<ContainerProps> = ({ children, className, ...rest }: ContainerProps) => {
    return (
        <div className={classNames('mx-auto w-[100%] max-w-[1264px] px-[16px] tablet:px-[24px]', className)} {...rest}>
            {children}
        </div>
    )
}
